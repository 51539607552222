
.topic-process-detail {
    height: 100%;
    & * {
        box-sizing: border-box;
    }
    ul, li {
        padding: 0px;
        margin: 0px;
    }
    .topic-complete-type {
        height: 68px;
        width: 100%;
        background-color: #FFF;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .bg-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 30px;
            &:last-child {
                margin-right: 0px;
            }
            .bg-item {
                width: 30px;
                height: 30px;
                border-radius: 4px;
                margin-right: 10px;
                &.bg {
                    border: 1px solid #DDDDDD;
                }
            }
        }
    }
    .topic-process-conent {
        height: calc(100% - 108px);
        &::v-deep {
            .el-timeline-item {
                margin-left: 8px;
            }
            .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
        .time-line-main {
            cursor: pointer;
            width: 100%;
            display: inline-grid;
            background-color: #FFF;
            border-radius: 8px;
            padding: 20px;
            min-height: 100px;
            grid-template-columns: repeat(6, minmax(10px,1fr));
            grid-column-gap: 10px;
            grid-row-gap: 20px;
            .item-process-item {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                width: 40px;
                height: 40px;
                &.bg {
                    border: 1px solid #DDDDDD;
                }
            }
        }
        &::v-deep .el-timeline {
            .el-timeline-item {
                &.el-timeline-done {
                    .el-timeline-item__tail {
                        border-left: 2px solid #67C23A;
                    }
                }
            }
        }
    }

}

