
.topic-item {
    .topic-title {
        width: 100%;
        height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
        &::v-deep span.blank-item-one {
            padding: 0px 10px;
            border-bottom: 1px solid #000;
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
        .input-blank-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
            margin: 10px;
            span.number {
                margin-right: 10px;
            }
        }
    }
}
