
.topic-item {
    .topic-title {
        width: 100%;
        height: 50px;
        background-color: #F2F3FA;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        &::v-deep .title {
            display: inline-block;
            width: calc(100% - 30px);
            font-size: 14px;
            color: #343441;
            p {
                display: inline-block;
            }
        }
    }
    .topic-options {
        background-color: #FFF;
        padding: 13px 0 19px 70px;
        .el-radio-item {
            margin-bottom: 20px;
        }
    }
    .el-radio-item {
        &::v-deep {
            .el-radio__input {
                &.is-checked {
                    .el-radio__inner {
                        background-color: #1122D8;
                        border-color: #1122D8;
                    }
                    &+.el-radio__label {
                        color: #1122D8;
                    }
                }
            }
        }
    }
}

